body {
  font-size: 14px;
  line-height: 1;

  font-family: 'Open Sans', sans-serif;

  background: $w-darken-green;
}

.wrapper {
  position: absolute;
  top: 9rem;
  left: 0;
  right: 0; 

  width: 100%;

  transition: all .1s linear;

  &.small {
    left: 12%;
    width: 88%;
  }
}

.container {
  max-width: 90%;
  width: 90%;

  margin: 0 auto;
  padding: 0;
}

.content {
  position: relative;

  // padding: 1rem;

  background: $w-black;
  border-radius: 1rem;

  font-size: 1rem;
  font-weight: 600;
  color: $w-white;
}

.codeblock {
  display: block;

  padding: 1rem;

  width: 100%;

  background-color: $lightgrey;
  color: $w-black;
  border-radius: 1rem;
}

.send__status {
  &.success {
    background: $w-dark-green;
  }
  &.error {
    background: $lightred;
  }
  &.process {
    background: $lightyellow;
  }
}

.full__height {
  height: 100%;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  // background: linear-gradient(40deg, $w-dark-green, $w-black);
  background: $w-dark-green;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform .2s ease-out;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

::-webkit-scrollbar{
  width: 6px !important;
  height: 6px !important;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: rgba($w-white, .1);
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($w-white, .2);
  cursor: pointer;
}

:root {
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
}
