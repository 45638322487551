
.toast{
	position: fixed;
	right: 25px;
	top: 25px;
	z-index: 999999;

	color: white;
	background-color: #008069;
	border: 1px solid #ccc;
	border-radius: 10px;

	width: auto;
	padding: 20px 25px;

	font-size: 18px;
	text-align: center;
	
	&-title{
		margin-bottom: 5px;
	}
	& .toast-close-button{
		position: absolute;
		right: 6px;
		top: -4px;
		background-color: transparent;
		color: white;
		font-size: 25px;
	}
	&.toast-error{
		background-color: rgb(144, 0, 0);
	}
  }