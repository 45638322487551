.chat__list__data__state {
  
}

.receiving__status {
  display: flex; 
  align-items: center;
  justify-content: center;
  
  padding: 1rem;
  
  border: 1px solid $w-lightblack;

  &.hidden {
    display: none !important;
  }
  
  &__img {
    width: 2rem;
    height: 2rem;

    &__block {
      margin-right: 2rem;  
    }
  }
  
  &__text {
    font-size: 1rem; 
  }
}