@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@import "reset_styles";
@import "variables";
@import "publics";

@import "components/header";
@import "components/sidebar";
@import "components/documentation";
@import "components/send";
@import "components/modal_window";
@import "components/statistic";
@import "components/chat";
@import "components/messages";
@import "components/auth";
@import "components/home";
@import "components/settings";
@import "components/toast";
@import "components/receiving_status";