$blue: #4f6380;
$dark_blue: #6610f2;
$white: #ffffff;
$grey: #cccccc;
$green: #198754;
$dark_grey: #a9a9a9;
$black: #000000;
$lightgrey: #dfdfdf;
$dark_green: #128C7E;

$lightgreen: lightgreen;
$lightyellow: #ffc107;
$lightred: #dc3545;

$primary: #3b4a54;
$w-black: #111b21;
$app-background: #eae6df;
$app-background-deeper: #d1d7db;
$panel-background-lighter: #f7f8fa;
$search-container-background: #fff;
$search-input-background: #f0f2f5;
$system-message-background: hsla(0,0%,100%,0.95);

$avatar-placeholder-background: #dfe5e7;
$w-white: #e9edef;
$w-dark-green: #00a884;
$w-dark-grey: #222e35;
$w-darken-green: #008069;
$w-lightgrey: #667781;
$w-lightblack: #202C33;
$secondary: #55636b;
$background-default-active: rgb(238, 237, 237);
$background-default: #fff;
$background-default-hover : #f5f6f6;
$black-rgb: rgb(11,20,26);
$white-rgb: rgb(255,255,255);

$conversation-panel-background: #efeae2;
$conversation-header-border: #d1d7db;
$conversation-panel-border: #e9edef;
$panel-header-background: #f0f2f5;
$system-message-text: #54656f;
$system-message-background: #fffffff2;
$shadow-rgb: rgb(11,20,26);
$message-primary: #111b21;
$outgoing-background: #d9fdd3;
$incoming-background: #fff;
$bubble-meta: #667781;
$icon-ack: #53bdeb;
