.sidebar {
  position: fixed;
  top: .5rem;
  bottom: .5rem;
  left: .5rem;
  z-index: 1;

  width: 3%;

  border-radius: 1rem;

  background: $w-black;

  overflow: hidden;

  transition: all .1s linear;

  &.active {
    width: 15%;
  }

  &__inner {
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__header {
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: $w-dark-green;

    &__icon {
      margin-right: 1rem;

      width: 2rem;
      height: 2rem;
      filter: invert(1);
    }

    &__text {
      font-size: 1rem;
      font-weight: 700;
      color: $w-white;
    }
  }

  &__nav {
    list-style: none;

    &__item {
      list-style: none;

      margin: 1.5rem 0;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      cursor: pointer;

      &:hover .sidebar__nav__item__name {
        color: $w-dark-green;
      }

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      
      &__icon {
        width: 2rem;
        height: 2rem;

        transition: all .1s linear;

        &__block {
          margin-right: 1rem;
        }
      }

      &__name {
        font-size: 1rem;
        font-weight: 700;
        color: $w-white;
      }
    }
  }
}