.send__statistic {
  

  &__header {
    width: 100%;
    margin-bottom: 2rem;

    text-align: center;
  }
  &__title {
    margin-bottom: 2rem;
  }
  &__subtitle {
    margin-bottom: 2rem;
  }

  &__body {}

  &__mailing__info {
    &__header {
      &__item {
        &__title {
          margin-bottom: 2rem;

          width: 100%;
          text-align: center;
        }
        &__text {
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 600;
          color: $w-white;

          &__block {
            padding: 1rem;
            margin: 0 auto;
            width: 100%;
          }
        }
      }
    }

    &__footer {
      &__title {
        // display: block;
        width: 100%;
        text-align: center;
        margin: 2rem 0;
      }

      &__table {
        width: 100%;
        text-align: left;

        border: 1px solid $w-white;

        tr, th, td {
          // width: 100%;
          padding: 1rem;
          border: 1px solid $w-white;
        }
        

      }
    }
  }
}