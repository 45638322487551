.modal__window {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100000;

  transform: translateX(-50%);

  width: 100%;
  height: 100%;

  display: none;
  align-items: center;
  justify-content: center;

  background: rgba($w-black, .7);

  &.active {
    display: flex;
  }

  &__inner {
    width: 40%;

    padding: 1rem;

    background: $w-white;
    border-radius: 1rem;
  }
}