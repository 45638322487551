.send {
  &__status {
    &.success {
      background: $w-dark-green;
    }
    &.waiting {
      background: $lightyellow;
    }
    &.unsubscribed {
      background: $blue;
    }
    &.error {
      background: $lightred;
    }
  }
  &__header {
    padding: 1rem;
    margin-bottom: 1rem;

    text-align: center;
  }

  &__title {
    margin-bottom: 2rem;

    font-size: 2.5rem;
    font-weight: 600;
    color: $w-white;
  }
  &__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: $w-white;
  }

  &__body {
    &__title {
      margin-bottom: 2rem;

      text-align: center;

      font-size: 2rem;
      font-weight: 600;
      color: $w-white;
    }
    &__search__block {
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;

      &__input {
        width: 50%;

        padding: 1rem;

        border: 3px solid $w-black;
        border-radius: 1rem;

        font-size: 1rem;
        font-weight: 600;
        color: $w-black;

        &:hover, &:active {
          border-color: $w-dark-green;
        }
      }

      &__new {
        margin-left: 1rem;

        background: $w-black;

        &__icon {
          width: 2rem;
          height: 2rem;

          filter: invert(1);
        }
      }
    }

    &__filter__block {
      margin: 1rem auto;
      width: 80%;

      &__list {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__btn {
        margin-right: 1rem;
        padding: 1rem;

        display: block;

        border-radius: 1rem;

        font-size: 1rem;
        font-weight: 600;
        color: $w-white;

        background: $blue;

        &.active {
          background: $w-dark-green;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__mailing { 
      &__list {
        margin-top: 2rem;
      }

      &__item {
        margin-bottom: 1rem;
        padding: 1rem;

        border: 1px solid $lightgrey;
        border-radius: 1rem;

        &.created {
          border-color: $icon-ack;
        }
        &.work {
          border-color: $lightyellow;
        }
        &.error {
          border-color: $lightred;
        }
        &.complete {
          border-color: $w-dark-green;
        }

        &__inner {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.statistic {
            padding: 1rem;
            margin-bottom: 1.5rem;

            border: 1px solid $lightgrey;
            border-radius: 1rem;

            &.created {
              border-color: $icon-ack;
            }
            &.work {
              border-color: $lightyellow;
            }
            &.error {
              border-color: $lightred;
            }
            &.complete {
              border-color: $w-dark-green;
            }
          }
        }

        &__left {
          width: 25%;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__center {
          width: 15%;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        // &__id {
        //   width: 5%;
        // }

        // &__title {
        //   width: 40%;
        // }
        // &__date {
        //   width: 10%;
        // }
        // &__count {
        //   width: 10%;
        // }
        // &__send {
        //   width: 10%;
        // }

        &__buttons {
          width: 15%;

          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__button {
          cursor: pointer;

          &__icon {
            position: relative;
            &__popup {
              display: none;

              &.active {
                display: block;
              }

              position: absolute;
              top: -6.5rem;
              left: 30%;
              z-index: 1;
              transform: translateX(-50%);

              width: 15rem;

              padding: 1rem;

              border-radius: 1rem;

              &.start {
                background: $dark_blue;
              }
              &.change {
                background: $lightyellow;
              }
              &.statistics {
                color: $w-white;
                background: $w-dark-green;
              }
              &.stop {
                background: $icon-ack;
                top: -7.5rem;
                left: 45%;
              }
              &.delete {
                background: $lightred;
              }

              &__title {
                font-size: 1rem;
                font-weight: 600;
                color: $w-white;
              }

              &__angle {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);

                z-index: 1;
                height: 0;
                border-style: solid;
                border-width: 1rem 1rem 1rem;
                border-color: $dark_blue transparent transparent transparent;

                &.start {
                  border-color: $dark_blue transparent transparent transparent;
                }
                &.change {
                  border-color: $lightyellow transparent transparent transparent;
                }
                &.statistics {
                  border-color: $w-dark-green transparent transparent transparent;
                }
                &.stop {
                  border-color: $icon-ack transparent transparent transparent;
                }
                &.delete {
                  border-color: $lightred transparent transparent transparent;
                }

              }
            }
            svg {
              position: relative;
              fill: $w-white;
              width: 2rem;
              height: 2rem;
            }
          }

        }
      }
    }
  }

  &__window {
    display: flex;

    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &__header {
      margin-bottom: 1rem;

      width: 100%;

      text-align: center;
    }

    &__title {}

    &__name {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 1rem;

      &__input {
        width: 100%;

        padding: 1rem;

        border: 2px solid $lightgrey;
        border-radius: 1rem;

        font-size: 1rem;
        font-weight: 600;
        color: $w-black;

        &:hover, &:active {
          border-color: $w-dark-green;
        }
      }
    }

    &__body {
      width: 100%;

      &__media {
        margin: 1rem auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        text-align: center;

        input:hover + label,
        input:focus + label {
          transform: scale(1.02);
        }
        input:focus + label {
        }

        &__name {
          margin-top: 1rem;
        }

        &__preview {
          position: relative;
          margin-top: 1rem;

          &__img {
            height: 8rem;
            width: auto;
          }

          &__remove {
            position: absolute;
            top: -1rem;
            right: -2rem;
            z-index: 10000;

            background: $w-dark-green;
            border-radius: 50%;

            &:hover {
              background: $green;
            }

            &__icon {
              cursor: pointer;
              width: 1.5rem;
              height: 1.5rem; 

              padding: .2rem;

              filter: invert(1);

              transform: rotate(45deg);
            }
          }
        }
      }

      &__phones {
        margin-bottom: 1rem;

        &__title {
          margin-bottom: 1rem;

          text-align: center;
        }

        &__field {
          min-height: 10rem;
          width: 100%;

          padding: 1rem;

          border: 2px solid $lightgrey;
          border-radius: 1rem;

          font-size: 1rem;
          font-weight: 400;

          transition: all .1s linear;

          resize: none;

          &:hover, &:focus {
            border-color: $w-dark-green;
          }
        }
      }

      &__message {
        &__title {
          margin-bottom: 1rem;

          text-align: center;
        }

        &__field {
          min-height: 10rem;
          width: 100%;

          padding: 1rem;

          border: 2px solid $lightgrey;
          border-radius: 1rem;

          font-size: 1rem;
          font-weight: 400;

          resize: none;

          transition: all .1s linear;

          &:hover, &:focus {
            border-color: $w-dark-green;
          }
        }
      }

      &__time {}
    }

    &__footer {
      margin-top: 1rem;
      width: 100%;  
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__button {
      padding: 1rem 2rem;
      margin-right: 1rem;

      border: 1px solid $w-dark-green;
      border-radius: 1rem;

      transition: all .1s linear;

      &:last-child {
        margin-right: 0;
      }

      &.cancel {
        &:hover {
          background: $lightred;
          border-color: $lightred;
          color: $w-white;
        }
      }

      &.save {
        &:hover {
          background: $w-dark-green;
          color: $w-white;
        }
      }

    }

  }

  &__remove {
    &__window {
      &__title {
        margin-bottom: 1rem;

        text-align: center;
      }

      &__buttons {
        display: flex;
        align-items: center; 
        justify-content: center;
      }

      &__button {
        padding: 1rem 2rem;
        margin-right: 1rem;

        border: 1px solid $w-dark-green;
        border-radius: 1rem;

        transition: all .1s linear;

        &:last-child {
          margin-right: 0;
        }

        &.cancel {
          &:hover {
            background: $lightred;
            border-color: $lightred;
            color: $w-white;
          }
        }

        &.confirm {
          &:hover {
            background: $w-dark-green;
            color: $w-white;
          }
        }
      }
    }
  }
}
