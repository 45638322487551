.chat {
  width: 100%;

  &__window {
    position: fixed;
    top: 0;
    left: 0;
    
    width: 100%;
    // max-height: 83.5vh;
    height: 100%;


    color: $primary;
    font-size: 1rem;
    font-weight: 600;

    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @media (max-width:768px) {
      display: block;
    }
  }

  &__list {
    width: 25%;
    height: 100%;
    transition: .3s;

    background: $background-default;

    &__header {
      padding: 1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid $search-input-background;;
      border-top-left-radius: 1rem;

      svg {
        width: 2rem;
        height: 2rem;
      }

      &__inner {
        width: 100%;

        padding: .2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background: $panel-header-background;
        color: $w-white;

        border-radius: .5rem;
      }

      &__input {
        width: 100%;
        display: block;
        background: none;

        padding-left: .5rem;
        margin-right: 1rem;

        white-space: nowrap;
        color: $primary;

        font-size: 1rem;
        font-weight: 600;

        transition: all .1s linear;

        &:hover, &:active {
          border-color: $w-dark-green;
        }
      }

      &__btn {
        padding: .5rem .8rem;

        border-radius: 1rem;

        font-size: 1.2rem;
        font-weight: 600;
        color: $w-black;

        transition: all .1s linear;

        &:hover {
          background: $w-black;
          color: $w-white;
        }
      }
    }

    &__inner {
      height: 85%;

      overflow: hidden;
      overflow-y: auto;

      border-right: 1px solid $search-input-background;;
      border-left: 1px solid $search-input-background;;
    }

    @media (max-width: 768px) {
      width: 100%;  
      position: absolute;
      z-index: 3;

      &.no-active{
        transform: translateX(-100%);
      }
    }
  }

  &__item {
    padding: 1rem;
    border-bottom: 1px solid $search-input-background;

    cursor: pointer;

    &.current {
      background-color: $search-input-background;
    }
    &:hover{
      background-color: $background-default-hover;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__image {
      width: 4rem;
      height: 4rem;

      border-radius: 50%;

      &.invert {
        // filter: invert(1);
        opacity: .5;
      }

      &__block {
        margin-right: 1rem;
      }
    }

    &__info {
      width: 80%;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      &__bottom {
        width: 100%;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &__name {
      margin-bottom: 1rem;

      font-size: 1.5rem;
      font-weight: 500;
      color: $w-black;
    }

    &__message {
      width: 50%;

      text-overflow: ellipsis;
      -webkit-line-clamp: 1; /* количество строк */
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap;
    }

    &__time {
      width: 50%;
      text-align: right;
    }
  }
}
