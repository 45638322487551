.header {
  position: absolute;
  top: .5rem;
  left: 0;
  right: 0; 

  width: 100%;

  transition: all .1s linear;


  &.small {
    left: 12%;
    width: 88%;
  }

  &__inner {
    position: relative;

    width: 100%;
    padding: 1rem;

    border-radius: 1rem;

    background-color: $w-black;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      width: 35%;
      height: 100%;

      display: flex;
      align-items: center;

      text-align: left;
    }

    &__center {}

    &__right {
      width: 20%;
      height: 100%; 

      position: relative;

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__logo {
    &__icon {
      cursor: pointer;
      fill: $w-white;

      transition: all .1s linear;

      &:hover {
        fill: $w-dark-green !important;
      }
    }
  }

  &__title {
    margin-left: 1rem;
    color: $w-white;
  }

  &__user {
    width: 50%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
      
    &__icon {
      width: 2rem;
      height: 2rem;

      filter: invert(1);
    }

    &__name {
      margin: .7rem;

      font-weight: 500;
      font-size: 1rem;
      color: $w-white;

      &:hover {
        color: $w-dark-green;
      }
    }

    &__toggle {

      &__icon {
      }
    }

    &:hover .header__user__options {
      opacity: 1;
      pointer-events: all;
    }

    &__options {
      position: absolute;
      top: 100%;
      right: -1rem;
      z-index: 10000;

      width: 100%;

      padding: 1rem;

      background: $w-black;
      border-radius: 1rem;

      border-top-left-radius: 0;
      border-top-right-radius: 0;

      opacity: 0;
      pointer-events: none;

      transition: all .1s linear;

      &__list {
      }
      &__item {
        margin-top: 1rem;

        font-size: 1rem;
        font-weight: 500;
        color: $w-white;

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $w-dark-green;
        }
      }
    }
  }

  &__theme {
    position: absolute;
    top: -.2rem;
    left: 50%;
    z-index: 100;

    transform: translateX(-80%);
  }
}

.toggle-switch {
  position: relative;
  width: 7rem;
}

label {
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 3rem;
  background-color: $w-lightblack;
  border-radius: 1rem;
  cursor: pointer;
}

.header__theme__input {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  transition: 0.3s;
}

.header__theme__input:checked ~ .slider {
  background-color: var(--light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  box-shadow: inset 1rem -.1rem 0px 0px var(--light);
  background-color: $w-lightblack;

  transform: translateY(-50%);
  transition: 0.3s;
}

.header__theme__input:checked ~ .slider::before {
  transform: translate3d(3rem, -50%, 0);
  background-color: var(--dark);
  box-shadow: none;
}