.messages {
  &__list {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    transition: .3s;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    width: 75%;
    height: 100%;

    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @media (max-width: 768px) {
      width: 100%;                       // уменьшьинть шрифты и доделать поиск - клик по сообщениям
    }
  }

  &__header {
    padding: .77rem 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $panel-header-background;
    border-top-right-radius: 1rem;

    &__left {
      display: flex;
      align-items: center;
      justify-content: center;

      &__img {
        width: 3rem;
        height: 3rem;
        min-height: 3rem;

        border-radius: 50%;
        &.invert {
          // filter: invert(1);
          opacity: .5;
        }

        &__block {
          margin-right: 1rem;
          display: flex;
          align-items: center;

          & .arrow-left{
            display: none;
            @media (max-width:768px) {
              display: block;
              width: 2rem;
              height: 2rem;
              margin-left: -5px;
            }
          }
        }
      }
      &__username {
        font-size: 1.4rem;
        font-weight: 400;
        color: $primary;

        @media (max-width:768px) {
          font-size: 1.1rem;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 90%;
      margin-left: 28px;

      svg {
        width: 2rem;
        height: 2rem;

        margin-left: .5rem;

        cursor: pointer;
      }

      &__search {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      &__input {
        transform: translateX(200%);
        width: 100%;
        // background: $background-default-active;
        padding-left: 0.5rem;
        margin-right: 1rem;
        white-space: nowrap;
        color: #3b4a54;
        font-size: 1rem;
        font-weight: 600;
        transition: all 0.1s linear;
        border-radius: .5rem;
        transition: .3;

        @media (max-width:768px) {
          transform: translateX(250%);
        }
        
      }
      &__input.active {
        transform: translateX(0);
        // display: block;
      }

      

      &__options {
        position: relative;

        &__menu {
          opacity: 0;

          &.active {
            opacity: 1;
          }

          position: absolute;
          top: 2rem;
          right: 0rem;
          z-index: 1000;

          padding: 1rem 2rem;

          width: 600%;

          background: $w-lightblack;

          transition: all .1s linear;

          &__inner {
            margin: 0 auto;
            width: 100%;
          }

          &__item {
            margin-bottom: 2rem;

            cursor: pointer;

            color: $w-white;

            transition: all .1s linear;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              color: $w-dark-green;
            }
          }
        } 
      }

    }
  }

  &__body {
    height: 100%;
    position: relative;
    background-color: #efeae2;

    &__find-window{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 20;
      background-color: $background-default-active;
      width: 100%;
      height: 100%;
      transition: .3s;
      transform: translateX(100%);

      &.active{
        transform: translateX(0);
      }
      &__title{
        text-align: center;
        display: inherit;
        margin: 20px 0;
        font-size: 1.2rem;
        font-weight: 400;
      }
      &__wrapper{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
      }

      &__message-item{
        width: 100%;
        height: 95px;
        padding: 1rem;
        margin: 0 auto;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $background-default;
        border-bottom: 1px solid #f0f2f5;

        &:hover{
          background-color: $background-default-hover;
        }
      }
      &__time{
        font-weight: 400;
        margin-bottom: 9px;
      }
      &__message{
        font-size: 1.2rem;
        font-weight: normal;
        overflow: hidden;
        text-overflow:  ellipsis;
        white-space: nowrap;
      }
    }
    
    &__list {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;

      overflow-x: hidden;
      overflow-y: scroll;

      height: 100%;
      width: 100%;

      &__inner {
        width: 90%;
        height: 100%;

        margin: 0 auto;

        position: relative;

        & span:last-child{
          height: 20px;
          display: inline-block;
        }

        &__date {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          margin: 1rem auto;

          &__text {
            padding: .5rem;
            padding: 5px 17px 6px;
            border-radius: .5rem;
            color: $system-message-text;
            font-size: 1.2rem;

            display: block;

            opacity: .8;
            background: $system-message-background;
            box-shadow: 0 2px 0.5px rgba($shadow-rgb,.13);
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    margin: 1rem 0;

    padding: .5rem 1rem;
    padding-top: 1rem;

    max-width: 50%;

    border-radius: 1rem;
    border-top-left-radius: 0;

    background: $incoming-background;

    overflow-wrap: break-word;

    box-shadow: 0 1px 0.5px rgba($shadow-rgb,.13);

    font-weight: 400;
    font-size: 18px;

    transition: .4s;

    @media (max-width:768px) {
      max-width: 80%;
    }

    &__arrow {
      position: absolute;
      top: -2.5px;

      z-index: 100;

      display: block;

      svg {
        width: 8px;
        height: 13px;
        transition: .4s;
      }
    }
    
    &__author {
      &.in {
        display: none;
      }
    }

    &.in {
      margin: 1rem 0 1rem 50%;

      background: $outgoing-background;

      border-top-right-radius: 0;
      border-top-left-radius: 1rem;

      @media (max-width:768px) {
        margin: 1rem 0 1rem 20%;
      }
    }

    &.message-finded{
      animation: flash 1.4s ease-in-out;
      animation-delay: .5s;

      & svg{
        animation: flashSvg 1.4s ease-in-out;
        animation-delay: .5s;
      }
    }

    @keyframes flash {
      0% {background-color: white;}
      50% {background-color: #3b4a54;
           color: white;
          }
    }
    @keyframes flashSvg {
      0% {fill: white;}
      50% {fill: #3b4a54;}
    }
    
    &__body {
      &__image {
        &.hidden {
          display: none;
        }
      }
      & .pg-viewer-wrapper{
        overflow: hidden;
      }
      & canvas{
        width: 100%;
      }
      & audio{
        width: 100%;
        height: 20px;
        margin-top: 10px;
        &::-webkit-media-controls-enclosure {
          background-color: transparent;
        }
        &::-webkit-media-controls-mute-button, &::-webkit-media-controls-time-remaining-display,
        &::-webkit-media-controls-volume-control-container{
          display: none;
        }
        &::-webkit-media-controls-play-button{
          background-size: 100%;
        }
      }
    }

    &__caption {
      margin-bottom: 1rem;
      overflow-wrap: break-word;

      line-height: 1.5;
      font-size: 1.1rem;

      overflow: hidden;

      @media (max-width:768px) {
       font-size: 1rem;
      }

      p {
        overflow-wrap: break-word;
        white-space: pre-wrap;

        a {
          color: $icon-ack;
        }
      }
    }

    &__time {
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      z-index: 100;
      font-size: 1rem;

      opacity: .4;
      @media (max-width:768px) {
        font-size: .7rem;
      }

      &__text {

      }
    }
  }

  &__media {
    width: 100%;
    height: 100%;

    margin-bottom: 1rem;

    border-radius: 1rem;

    &.img{
      min-height: 100px;
    }
    
  }
}