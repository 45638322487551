.auth {
  &__content {
    
  }

  &__header {
    margin-bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      padding: 1rem 2rem;

      font-size: 1.5rem;
      font-weight: 600;

      cursor: pointer;

      user-select: none;

      &.active {
        background: $w-dark-green;
        color: $w-white;
      }
    }
  }

  &__registration {
    border: 1px solid $w-dark-green;

    border-radius: 1rem;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &__authorization {
    border: 1px solid $w-dark-green;
    border-left: 0;

    border-radius: 1rem;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__body {
    margin-bottom: 2rem;

    input {
      display: block;

      width: 100%;

      padding: 1rem;
      margin-bottom: 1rem;  

      border-radius: 1rem;
      border: 1px solid $w-lightgrey;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover, &:active {
        border: 1px solid $w-dark-green;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    padding: 1rem 2rem;
    margin-right: 2rem;

    border-radius: 1rem;
    border: 1px solid $w-black;

    transition: all .1s linear;

    cursor: pointer;

    font-size: 1rem;
    font-weight: 600;
    color: $w-black;

    &:last-child {
      margin-right: 0;
    }

    &.confirm {
      &:hover {
        border-color: $w-dark-green;
        background-color: $w-dark-green;
        color: $w-white;
      }
    }

    &.cancel {
      &:hover {
        border-color: $lightred;
        background-color: $lightred;
        color: $w-white;
      }
    }
  }
}