.documentation {
  &__header {
    padding: 1rem;
    margin-bottom: 1rem;

    text-align: center;
  }

  &__title {
    margin-bottom: 2rem;

    font-size: 2.5rem;
    font-weight: 600;
    color: $w-white;
  }
  &__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: $w-white;
  }

  &__text {
    margin: 1rem;

    font-size: 1rem;
    font-weight: 600;

    color: $w-white;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {}

  &__item {
    margin-bottom: 1rem;
    padding-left: 0;

    border: 1px solid $w-white;
    border-radius: 1rem;

    font-size: 1rem;
    font-weight: 600;
    color: $w-white;

    cursor: pointer;

    &__query {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.active {
        border-bottom: 1px solid $lightgrey;

        .documentation__item__query__type {
          border-bottom-left-radius: 0;
        }
      }

      &__type {
        padding: 2rem;

        border-radius: 1rem;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        
        &.POST {
          background-color: $dark_blue;
        }

        &.GET {
          background-color: $green;
        }

        &__text {
          user-select: none;
        }
      }

      &__text {
        margin-left: 1rem;

        user-select: none;
      }

      &__name {
        margin-bottom: 1rem;

        font-weight: 700;
      }

      &__description {
        user-select: none;
        color: $dark-blue;
      }

    }

    &__body {
      overflow: hidden;
      max-height: 0;

      transition: max-height .2s linear;
      transition: padding .1s linear;

      &.active {
        padding: 1rem;
        max-height: 100%;
      }

      &__request {
        padding-top: 1rem;

        &__title {
          user-select: none;
          margin-bottom: 1rem;

          color: $dark-blue;
        }

        &__params {
          width: 100%;

          &__block {
            width: 100%;
            padding: 1rem;

            text-align: left;

            &__header {
              display: block;
              text-align: center;

              tr {
                padding-bottom: 1rem;

                display: flex; 
                align-items: center;
                justify-content: space-between;

                border-bottom: 1px solid $lightgrey;
              }

              th {
                width: 25%;
                
                &:first-child {
                  width: 10%;
                }

                &:nth-child(2) {
                  width: 40%;
                }
              }
            }

            &__body {
              display: block;
              text-align: center;

              tr {
                display: flex; 
                align-items: center;
                justify-content: space-between;

                border-bottom: 1px solid $w-lightgrey;
              }

              th, td {
                padding: 1rem;

                width: 25%;

                &:nth-child(2) {
                  width: 40%;
                }
              }

              th {
                width: 10%;
              }
            }
          }
        }
      }

      &__response {
        &__title {
          margin-bottom: 2rem;
          color: $dark-blue;
        }
      }
    }
  }
}
