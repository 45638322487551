.settings {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__auth {
    position: relative;

    padding: 1rem;

    width: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-right: 1px solid $w-white;

    &__title {
      margin-bottom: 2rem;
    }

    &__inner {
      width: 95%;

      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &__instruction {
      display: block;

      &__item {
        margin-bottom: 1rem;

        font-size: 1.2rem;
        font-weight: 600;
        color: $w-white;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__qr {
      &__block {
        width: 60%;

        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }


      &__image {
        width: 24rem;
        height: 24rem;

        margin-bottom: 2rem;
      }


      &__btn {
        padding: 1rem 2rem;
        background: none;

        border: 1px solid $w-white;
        border-radius: 1rem;

        font-size: 1rem;
        font-weight: 600;
        color: $w-white;

        transition: all .1s linear;

        &:hover {
          background: $w-white;
          color: $w-black;
        }
      }
    }
  }

  &__user {
    padding: 1rem;

    width: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
      margin-bottom: 2rem;
    }

    &__subtitle {
      margin-bottom: 1rem;
    }

    &__img {
      &__block {}
    }

    &__form {
      width: 80%;

      text-align: center;

      &__title {
        margin: 1rem 0;
      }

      &__input {
        width: 100%;

        padding: 1rem;

        border-radius: 1rem;
      }
    }

    &__btn {
      margin-top: 2rem;
      padding: 1rem;

      background: none;

      border: 1px solid $w-white;
      border-radius: 1rem;

      font-size: 1rem;
      font-weight: 600;
      color: $w-white;

      transition: all .1s linear;

      &:hover {
        background: $w-white;
        color: $w-black;
      }
    }

  }
}

.settings__auth__qr__image {
  display: flex;
  align-items: center;
  justify-content: center;
   
  text-align: center;
  border: 1px solid $w-white;
}
